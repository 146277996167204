<template>
  <div class="container min-vh-100 d-flex flex-column">
    <div id="blocking-overlay" v-if="waitingForResponse">
      <div id="blocking-spinner" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="https://bayesfusion.com/">
          <img style="height: 3.5rem; margin-right: 1rem" :src="bflogo">
          <span>BayesFusion Metalog Builder</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse order-3" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="https://prob.bayesfusion.com/">Probability Distribution Visualizer</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="useful-links" href="#" role="button" @click="toggleLinksFocus"  @blur="blurLinks">
                Useful Links
              </a>
              <ul class="dropdown-menu bg-light" :style="`display:${displayLinks ? 'block' : 'none'}`">
                <li><a class="dropdown-item" href="https://bayesfusion.com">Main BayesFusion Website</a></li>
                <li><a class="dropdown-item" href="https://repo.bayesfusion.com">Interactive Model Repository</a></li>
                <li><a class="dropdown-item" href="https://support.bayesfusion.com/docs">Documentation</a></li>
                <li><a class="dropdown-item" href="https://support.bayesfusion.com/forum">Support Forum</a></li>
                <li><a class="dropdown-item" href="https://www.youtube.com/c/bayesfusion">BayesFusion YouTube Channel</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="https://en.wikipedia.org/wiki/Metalog_distribution" target="_blank">Wikipedia article about Metalog</a></li>
                <li><a class="dropdown-item" href="http://metalogdistributions.com/" target="_blank">Tom Keelin's Metalog Website</a></li>
                <li><a class="dropdown-item" href="https://www.youtube.com/channel/UCyHZ5neKhV1mSsedzDBoqyA" target="_blank">The Metalog Distributions<br> YouTube Channel</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="showHelpModal">Help <i class="bi bi-question-square"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="mt-2 mb-5">
      <div class="row">
        <div v-if="error.errorOccurred && error.errorIdx === -1" class="col-12 alert alert-danger alert-dismissible fade show" role="alert">
          {{this.error.errorMessage}}
          <button type="button" class="btn-close" @click="this.error.errorOccurred = false" aria-label="Close"></button>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-4 col-12 mb-5">
          <h6 class="small">Bounds and Quantile Parameters:</h6>
          <form class="form-floating col-12 col-md-8 col-lg-12 m-auto ">
            <input type="text" :class="`form-control ${this.lowerBoundValid ? '' : 'is-invalid'}`"
                   id="input-lower-bound" placeholder="-inf"
                   v-model="lowerBound"
                   data-bs-toggle="popover"
                   data-bs-placement="top"
                   data-bs-content=""
                   @focusout="removePopoverText('input-lower-bound')">
            <label for="input-lower-bound">Lower Bound</label>
          </form>
          <form class="form-floating col-12 col-md-8 col-lg-12 m-auto mt-2">
            <input type="text" :class="`form-control ${this.upperBoundValid ? '' : 'is-invalid'}`" id="input-upper-bound" placeholder="inf" v-model="upperBound"
                   data-bs-toggle="popover"
                   data-bs-placement="top"
                   data-bs-content=""
                   @focusout="removePopoverText('input-upper-bound')">
            <label for="input-upper-bound">Upper Bound</label>
          </form>
          <div class="col-12 col-md-8 col-lg-12 m-auto mt-4">
            <div class="btn-group btn-group-sm mb-1 w-100" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-primary w-33" data-bs-toggle="tooltip" data-bs-placement="bottom" @click="addPercentile">Add</button>
              <button type="button" class="btn btn-primary w-33" data-bs-toggle="tooltip" data-bs-placement="bottom" :disabled="selectedPercentile === -1" @click="insertPercentile(selectedPercentile)">Insert</button>
              <button type="button" class="btn btn-primary w-33" data-bs-toggle="tooltip" data-bs-placement="bottom" :disabled="selectedPercentile === -1" @click="deletePercentile(selectedPercentile)">Delete</button>
            </div>
            <div class="btn-group btn-group-sm mb-1 w-100" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-primary w-33" data-bs-toggle="tooltip" data-bs-placement="bottom" :disabled="percentiles.length === 0" @click="copyPercentilesToClipboard">Copy</button>
              <button type="button" class="btn btn-primary w-33" data-bs-toggle="tooltip" data-bs-placement="bottom" @click="pastePercentilesFromClipboard">Paste</button>
              <button type="button" class="btn btn-primary w-33" data-bs-toggle="tooltip" data-bs-placement="bottom" :disabled="percentiles.length < 2" @click="sortPercentiles">Sort</button>
            </div>
          </div>
          <div v-if="percentiles.length === 0" class="alert alert-info mt-1 small col-12" role="alert">
            <i class="bi bi-info-circle"></i><br>
            Use the buttons above to add or modify the quantiles used to generate the Metalog distribution chart.
            At least two quantiles must be provided to generate a Metalog.
            They probabilities must be greater than zero and less than one.<br>
            <i class="bi bi-info-circle"></i>
          </div>
          <div v-else class="mb-4 col-12 col-md-8 col-lg-12 percentile-list m-auto">
            <div class="input-group rounded-0 input-group-sm">
              <div class="rounded-0 input-group-text">
                <input class="form-check-input rounded-0 mt-0" style="opacity: 0" type="radio" disabled="disabled">
              </div>
              <input type="text" class="form-control small rounded-0" value="Probability" disabled="disabled" >
              <input type="text" class="form-control small rounded-0" value="Quantile" disabled="disabled">
            </div>
            <div class="input-group rounded-0 input-group-sm" v-for="(percentile, idx) in percentiles" :key="percentile">
              <div class="input-group-text rounded-0">
                <input class="form-check-input  mt-0" type="radio" :value="idx" v-model="selectedPercentile">
              </div>
              <input :id="`input-${idx}-percentile`"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     type="text"
                     class="form-control rounded-0 num-input"
                     v-model="percentile.percentile"
                     @paste="pastePercentilesInExactPlace"
                     @focus="selectPercentile(idx, 'percentile')"
                     @focusout="removePopoverText(`input-${idx}-percentile`)">
              <input :id="`input-${idx}-percentile-value`"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-content="" type="text"
                     class="form-control rounded-0 num-input"
                     v-model="percentile.value"
                     @paste="pastePercentilesInExactPlace"
                     @focus="selectPercentile(idx, 'value')"
                     @focusout="removePopoverText(`input-${idx}-percentile-value`)">
            </div>
          </div>
          <button :disabled="percentiles.length < 2" id="recalcButton" @click="fetchData" type="button" class="btn btn-primary col-12 col-md-8 col-lg-12 mb-2">Recalc</button>
          <button :disabled="selectedChartIdx < 0" id="getMetalogAButton" type="button" class="btn btn-outline-primary col-12 col-md-8 col-lg-12 mb-4" data-bs-toggle="modal" data-bs-target="#metalogAModal">Show Function</button>

        </div>
        <div class="col-xxl-5 col-xl-5 col-lg-8 col-12 px-xxl-4 mb-5">
          <h6 class="small">Select a distribution from the family displayed below:</h6>
          <div class="row" style="height: 75vh;">
            <div v-for="(chart, idx) in charts" @click="selectChart(idx)" :key="chart" :class="`chart-to-select chart-container ${selectedChartIdx === idx ? 'selected-chart' : ''} ${pdfClass}`" :style="`${idx}` in metalogResult.pdf ? '' : 'display:none'">
              <canvas :id="`pdf-chart-${idx}`" :style="`${idx}` in metalogResult.pdf ? '' : 'display:none'" :class="`pdf-chart`"></canvas>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-12 mb-5">
          <h6 class="small">PDF and CDF for the selected value of k:</h6>
          <div style="height: 75vh;">
            <div class="chart-container col-12 h-50" >
              <canvas id="selected-pdf-chart" :style="selectedChartIdx !== -1 ? '' : 'display:none'"></canvas>
            </div>
            <div class="chart-container col-12 h-50">
              <canvas id="selected-cdf-chart" :style="selectedChartIdx !== -1 ? '' : 'display:none'"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-xl fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5>Welcome to BayesFusion Metalog Builder</h5>
            </div>
            <div class="modal-body small" style="text-align: left">
              <span>
                This page offers an interactive tool for fitting a metalog distribution to a series of probability quantiles (based on Keelin and Powley, 2011).  Metalog distribution (also known as the Keelin distribution) is extremely flexible and is capable of fitting many naturally occurring distributions. It can be specified by probability quantiles and it is able to represent distributions that are unbounded, semi-bounded, and bounded.
                <br>
                <br>
                The interface will accept <i>–inf</i> and <i>inf</i> for the lower and upper bounds respectively. Quantiles in the table are constrained to be growing with higher probability values.  Both columns can be copied and pasted.  Generally, the higher the number of quantiles specified, the more complex and flexible the distribution.  However, high number of quantiles carries the danger of overfitting the data, so we advise prudent caution, looking at the distributions generated for different values of <i>k</i>, and selecting a not-too-high value of <i>k</i> that produces the desired distribution.
                <br>
                <br>
                This page mimics the functionality of the Metalog Builder window in GeNIe, which in turn is inspired by the elicitation tools built by Tom Keelin and colleagues.  For more information about the interface, please refer to the <a href="https://support.bayesfusion.com/docs/GeNIe/index.html?equations_metalog-builder.html" target="_blank">GeNIe Modeler manual page for the Metalog Builder</a>.
                <br>
                <br>
                Any plot in the Metalog Builder can be copied and later pasted as a picture into any other application by right-clicking on the plot and choosing <i>Save image as…</i> or <i>Copy image</i>.  To obtain the metalog definition corresponding to the selected plot, please click on the <i>Show Function</i> button.  The resulting window allows for copying the definition so that it can be pasted into other programs, for example, the <a href="https://prob.bayesfusion.com">Distribution Visualizer</a>, a companion page to the Metalog Builder.
                <br>
                <br>
                The example probability quantiles are derived from the Steelhead data set, made available to the community by Tom Keelin (Thomas W. Keelin. The Metalog Distributions. <i>Decision Analysis</i> 13(4):243-277, 2016).
                <br>
                <br>
                For more information about the metalog distribution, please look at the comprehensive <a href="https://en.wikipedia.org/wiki/Metalog_distribution">article on the topic on Wikipedia</a>, the <a href="http://metalogdistributions.com/">Metalog Distribution web site</a>, and the <a href="https://www.youtube.com/channel/UCyHZ5neKhV1mSsedzDBoqyA">Metalog Distributions YouTube channel</a>.  These sources provide access to articles by Tom Keelin and colleagues on the topic of the metalog distribution.
                <br>
                <br>
                The backend of this application runs on SMILE, BayesFusion's cross-platform library for reasoning and learning/causal discovery engine for graphical models, such as Bayesian networks, influence diagrams, and structural equation models.  For more information on SMILE and GeNIe please visit <a href="https://www.bayesfusion.com">BayesFusion’s website</a>.
              </span>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
            <div class="modal fade" id="metalogAModal" tabindex="-1" aria-labelledby="metalogAModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <code>{{metalog}}</code>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="copyMetalogAToClipboard" data-bs-dismiss="modal">Copy to clipboard</button>
                  </div>
                </div>
              </div>
            </div>
    </div>
    <div class="footer navbar fixed-bottom bg-white container">
      <div class="container-fluid">
        <div class="navbar order-3">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link footer-contact" href="https://bayesfusion.com/">© Copyright {{new Date().getFullYear()}} BayesFusion, LLC</a>
            </li>
          </ul>
        </div>
        <div class="navbar order-3">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="https://www.bayesfusion.com/contact/" >Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Chart from "chart.js/auto";
import axios from "axios";
import {shallowRef} from "vue";
import {initPercentiles, initResponse, initBounds} from "@/initData";
import { useCookies } from "vue3-cookies";

const bootstrap = require("bootstrap/dist/js/bootstrap");

const MAX_K = 16;

const isInteger = num => /^-?[0-9]+$/.test(num+'');
const isNumeric = num => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num+'');


function parsePercentiles(inputPercentiles) {
  return inputPercentiles.map((perc) => {
    return {
      "percentile": Number(perc.percentile),
      "value": Number(perc.value)
    }
  })
}

function addPopoverText(text, id) {
  document.getElementById(id).setAttribute("data-bs-content", text);
}

const focusErr = {
  lowerBound: (_ = null) => {
    document.getElementById("input-lower-bound").focus();
  },
  upperBound: (_ = null) => {
    document.getElementById("input-upper-bound").focus();
  },
  percentile: (idx = 0) => {
    document.getElementById(`input-${idx}-percentile`).focus();
  },
  percentileValue: (idx = 0) => {
    document.getElementById(`input-${idx}-percentile-value`).focus();
  }
}

const popoverErr = {
  lowerBound: (text = "", _ = null) => {
    const id = "input-lower-bound";
    addPopoverText(text, id);
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  },
  upperBound: (text = "", _ = null) => {
    const id = "input-upper-bound";
    addPopoverText(text, id);
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  },
  percentile: (text = "", idx = 0) => {
    const id = `input-${idx}-percentile`;
    addPopoverText(text, id);
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  },
  percentileValue: (text = "", idx = 0) => {
    const id = `input-${idx}-percentile-value`
    addPopoverText(text, id);
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 100);
  }
}

function removePopoverText(id) {
  document.getElementById(id).setAttribute("data-bs-content", "");
}

function splitInterleavedArrays(arr, arrCount = 2) {
  let result = new Array(arrCount);
  for (let i = 0; i < result.length; i += 1) {
    result[i] = new Array(arr.length / arrCount);
  }
  arr.forEach((val, idx) => {
    result[idx % arrCount][Math.floor(idx / arrCount)] = val;
  })
  return result;
}

function mean(arr) {
  return arr.reduce((a, b) => a + b) / arr.length;
}

function stdDev(arr) {
  const avg = mean(arr);
  const variance = arr.reduce((s, n) => s + (n - avg) ** 2, 0) / (arr.length - 1);
  return Math.sqrt(variance);
}

function formatNumber(perc) {
  let result = perc;
  let sign = "";
  if (result.startsWith("-")) {
    sign = "-";
    result = result.slice(1);
  }
  if (result.startsWith(".") && isNumeric(result.slice(1))) {
      result = "0" + result;
  }
  if (result.endsWith("%")) {
    const n = result.slice(0, -1);
    if (isNumeric(n)) {
      result = `${n / 100}`;
    }
  }
  return sign + result;

}

const SCALE_MARGIN = 1.05;

const errorMessage = {
  lowerBoundInvalid: () => ("Lower bound must be a numeric value or negative infinity (\"-inf\")."),
  upperBoundInvalid: () => ("Upper bound must be a numeric value or infinity (\"inf\")."),
  upperBoundGreaterThanLower: () => ("Upper bound must be greater than lower bound."),
  percentileNotANumber: (idx = 0) => (`Probability at index ${idx + 1} is not a number.`),
  percentileTooSmall: (idx = 0) => (`Probability at index ${idx + 1} is not positive.`),
  percentileTooLarge: (idx = 0) => (`Probability at index ${idx + 1} is greater or equal to 1.`),
  percentileValueNotANumber: (idx = 0) => (`Quantile at index ${idx + 1} is not a number.`),
  descendingValues: () => ("Quantile cannot decrease when probability increases."),
  sortingNonNumericValues: () => ("Cannot sort quantiles because of non numeric values"),
  nonNumericValueInClipboard: () => ("Content of the clipboard is invalid. One or more values are non-numeric."),
  invalidRowCountInClipboard: () => ("Content of the clipboard is invalid. More than two columns per row detected."),
  invalidServerResponse: (serverMsg = "") => (`Server error: ${serverMsg}`),
  lowerBoundGreaterThanSmallestPercentile: () => ("Lower bound cannot be greater than the smallest quantile."),
  upperBoundLessThanHighestPercentile: () => ("Upper bound cannot be less than the highest quantile.")
}

const DIVIDABLE_BY = 10;

function prettifyScale(scale) {
  const range = scale.max - scale.min;
  if (range < 1) { // do nothing
    return scale;
  } else if (range < 25) { // round to next integer
    return {min: Math.floor(scale.min), max: Math.ceil(scale.max)};
  } else { // round to next number dividable by ...
    return {
      min: scale.min < 0 ? scale.min - (DIVIDABLE_BY + (scale.min % DIVIDABLE_BY)) : scale.min - (scale.min % DIVIDABLE_BY),
      max: scale.max < 0 ? scale.max - (scale.max % DIVIDABLE_BY) : scale.max + (DIVIDABLE_BY - (scale.max % DIVIDABLE_BY))
    }
  }
}

export default {
  name: 'App',
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  computed: {
    pdfClass() {
      let c = "";
      const count = this.pdfCount();
      if (count < 5) {
        c += "col-12 ";
      } else if (count < 9) {
        c += "col-6 ";
      } else if (count < 13) {
        c += "col-4 ";
      } else {
        c += "col-3 ";
      }
      if (count === 1) {
        c += "h-100 ";
      } else if (count === 2) {
        c += "h-50 ";
      } else if ([3, 5, 6, 9].includes(count)) {
        c += "h-33 ";
      } else {
        c += "h-25 ";
      }
      return c;
    },
    metalog() {
      const stringifiedValues = [...this.metalogResult.percentiles].reverse().join(",");
      const k = this.selectedChartIdx;
      const loBound = this.metalogResult.lowerBound ?? "";
      const hiBound = this.metalogResult.upperBound ?? "";
      return `Metalog(${loBound},${hiBound},${k},${stringifiedValues})`;
    },
    metalogA() {
      const loBound = this.metalogResult.lowerBound ?? "";
      const hiBound = this.metalogResult.upperBound ?? "";
      let stringifiedCoeffs = "";
      if (typeof this.metalogResult.coeffs[`${this.selectedChartIdx}`] !== "undefined") {
        stringifiedCoeffs = this.metalogResult.coeffs[`${this.selectedChartIdx}`].join(",");
      }
      return `MetalogA(${loBound},${hiBound},${stringifiedCoeffs})`;
    },
    selectedPercentileComputed() {
      return this.selectedPercentile;
    },
    scales() {
      let localMinX = [];
      let localMaxX = [];
      let localMaxY = [];
      for (let k in this.metalogResult.pdf) {
        localMinX.push(Math.min(...this.metalogResult.pdf[`${k}`][0]));
        localMaxX.push(Math.max(...this.metalogResult.pdf[`${k}`][0]));
        localMaxY.push(Math.max(...this.metalogResult.pdf[`${k}`][1]));
      }
      return {x: prettifyScale({min: Math.min(...localMinX), max: Math.max(...localMaxX)}), y: {min: 0, max: Math.max(...localMaxY) * SCALE_MARGIN}};
    },
    selectedScales() {
      return {
        x: prettifyScale({
          min: Math.min(...this.metalogResult.pdf[`${this.selectedChartIdx}`][0]),
          max: Math.max(...this.metalogResult.pdf[`${this.selectedChartIdx}`][0])
        }),
        y: {
          min: 0,
          max: Math.max(...this.metalogResult.pdf[`${this.selectedChartIdx}`][1]) * SCALE_MARGIN
        }
      };
    },
    lowerBoundValid() {
      return (isNumeric(this.lowerBound) || this.lowerBound === "-inf" || this.lowerBound === "");
    },
    upperBoundValid() {
      return (isNumeric(this.upperBound) || this.upperBound === "inf" || this.upperBound === "");
    }
  },
  data() {
    return {
      displayLinks: false,
      waitingForResponse: false,
      lowerBound: "-inf",
      upperBound: "inf",
      binCount: 10,
      percentiles: [],
      selectedPercentile: -1,
      selectedPercentileType: "percentile",
      metalogResult: {
        percentiles: [],
        pdf: [],
        cdf: [],
        coeffs: [],
        lowerBound: "-Inf()",
        upperBound: "Inf()",
      },
      charts: [],
      selectedChart: {
        pdf: null,
        cdf: null,
      },
      selectedChartIdx: -1,
      error: {
        errorType: "",
        errorMessage: "",
        errorOccurred: false,
        errorIdx: -1
      },
      logo: require("@/assets/metalog-logo.png"),
      favicon: require("@/assets/favicon.png"),
      bflogo: require("@/assets/bf-logo.png")
    }
  },
  methods: {
    datasetColor(idx = 0) {
      const datasetColors = ['rgb(13, 110, 253)', 'rgb(255, 223, 0)'];
      return datasetColors[idx % datasetColors.length];
    },
    selectPercentile(idx, type) {
      this.selectedPercentile = idx;
      this.selectedPercentileType = type;
    },
    addPercentile() {
      this.selectedPercentile = -1;
      this.percentiles.push({ percentile: "", value: "" });
    },
    insertPercentile(atIndex) {
      this.selectedPercentile = -1;
      this.percentiles.splice(atIndex, 0, { percentile: "", value: "" });
    },
    deletePercentile(atIndex) {
      this.selectedPercentile = -1;
      this.percentiles.splice(atIndex, 1);
    },
    trimPercentiles() { // remove empty
      this.selectedPercentile = -1;
      this.percentiles = this.percentiles.filter((v) => (v.percentile !== "" && v.value !== ""));
    },
    formatPercentiles() {
      for (let i = 0; i < this.percentiles.length; i += 1) {
        this.percentiles[i].percentile = formatNumber(this.percentiles[i].percentile);
        this.percentiles[i].value = formatNumber(this.percentiles[i].value);
      }
    },
    sortPercentiles() {
      this.selectedPercentile = -1;
      this.trimPercentiles();
      this.formatPercentiles();
      const nonNumericPercentileIdx = this.percentiles.map((v) => isNumeric(v.percentile)).indexOf(false);
      if (nonNumericPercentileIdx !== -1) {
        this.error = {
          errorOccurred: true,
          errorMessage: errorMessage.percentileNotANumber(nonNumericPercentileIdx),
          errorType: "sortError"
        }
        return;
      }
      const nonNumericPercentileValueIdx = this.percentiles.map((v) => isNumeric(v.value)).indexOf(false);
      if (nonNumericPercentileValueIdx !== -1) {
        this.error = {
          errorOccurred: true,
          errorMessage: errorMessage.percentileValueNotANumber(nonNumericPercentileValueIdx),
          errorType: "sortError"
        }
        return;
      }
      this.percentiles.sort((a, b) => {
        return a.percentile - b.percentile;
      });
    },
    copyPercentilesToClipboard() {
      const val = this.percentiles.map((p) => `${p.percentile}\t${p.value}`).join("\n");
      navigator.clipboard.writeText(val).then(() => {}, () => {
        console.error("Clipboard write failed.");
      });
    },
    copyMetalogAToClipboard() {
      navigator.clipboard.writeText(this.metalog).then(() => {}, () => {
        console.error("Clipboard write failed.");
      });
    },
    pastePercentilesFromClipboard() {
      navigator.clipboard.readText().then((text) => {
        this.parseTextToPercentiles(text);
      });
    },
    pastePercentilesInExactPlace(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData('text');
      this.parseTextToPercentiles(paste);
    },
    toggleLinksFocus() {
      this.displayLinks = !this.displayLinks;
      if (this.displayLinks) {
        document.getElementById("useful-links").focus();
      }
    },
    blurLinks() {
      if (this.displayLinks) {
        setTimeout(() => {
          document.getElementById("useful-links").blur();
          this.displayLinks = false;
        }, 100); // Safari workaround - blur is always dispatched before click - even on the same element
      }
    },
    validateClipboard(text) {
      const nonNumericElementExists = text.split(/[\t\n\s]+/).includes((v) => !isNumeric(v));
      if (nonNumericElementExists) {
        return {
          valid: false,
          message: errorMessage.nonNumericValueInClipboard()
        };
      }
      const columnCount = text.split(/[\n,\r\n]+/).map((v) => v.split(/[\t\s]+/).length);
      const invalidColumnCount = columnCount.findIndex((v) => v > 2) !== -1;
      if (invalidColumnCount) {
        return {
          valid: false,
          message: errorMessage.invalidRowCountInClipboard()
        }
      }
      const twoColumnsExists = columnCount.findIndex((v) => v > 1) !== -1;
      return {
        valid: true,
        columnCount: twoColumnsExists ? 2 : 1
      };

    },
    parseTextToPercentiles(text) {
      const validatedClipboard = this.validateClipboard(text);
      if (!validatedClipboard.valid) {
        this.error = {
          errorOccurred: true,
          errorMessage: validatedClipboard.message,
          errorType: "clipboardInvalid"
        }
        return;
      }
      if (validatedClipboard.columnCount === 1) {
        const numArr = text.split(/[\t\n\s]+/).filter((v) => isNumeric(v));
        let start = this.selectedPercentile === -1 ? 0 : this.selectedPercentile;
        for (let i = start, pI = 0; pI < numArr.length; i += 1, pI += 1) {
          if (i < this.percentiles.length) {
            this.percentiles[i][this.selectedPercentileType] = numArr[pI];
          } else {
            let newPercentile = { percentile: "", value: ""};
            newPercentile[this.selectedPercentileType] = numArr[pI];
            this.percentiles.push(newPercentile);
          }
        }
      } else {
        const numRows = text.split(/[\n]+/);
        let start = this.selectedPercentile === -1 ? 0 : this.selectedPercentile;
        for (let i = start, pI = 0; pI < numRows.length; i += 1, pI += 1) {
          let newPercentile = { percentile: "", value: ""};
          if (i < this.percentiles.length) {
            newPercentile.percentile = this.percentiles[i].percentile;
            newPercentile.value = this.percentiles[i].value;
          }
          const splittedRow = numRows[pI].split(/[\t\s]+/);
          if (splittedRow.length > 0) {
            newPercentile.percentile = splittedRow[0];
          }
          if (splittedRow.length > 1) {
            newPercentile.value = splittedRow[1];
          }
          if (i < this.percentiles.length) {
            this.percentiles[i] = newPercentile;
          } else {
            this.percentiles.push(newPercentile);
          }
        }
      }

    },
    removePopoverText(id) {
      removePopoverText(id);
      let popovers = document.getElementsByClassName("popover");
      for (let i = popovers.length - 1; i >= 0; i -= 1) {
        popovers.item(i).remove();
      }
    },
    validateData() { // validate bounds and percentiles before sending them to the server
      let error = {
        errorMessage: "",
        errorOccurred: false,
        errorIdx: -1,
        errorType: ""
      };
      if (this.lowerBound === "") {
        this.lowerBound = "-inf";
      }
      if (this.upperBound === "") {
        this.upperBound = "inf";
      }
      if (!(isNumeric(this.lowerBound) || this.lowerBound === "-inf")) {
        return {
          errorMessage: errorMessage.lowerBoundInvalid(),
          errorOccurred: true,
          errorType: "lowerBound"
        }
      }
      if (!(isNumeric(this.upperBound) || this.upperBound === "inf")) {
        return {
          errorMessage: errorMessage.upperBoundInvalid(),
          errorOccurred: true,
          errorType: "upperBound"
        }
      }
      if (Number(this.upperBound) <= Number(this.lowerBound)) {
        return {
          errorMessage: errorMessage.upperBoundGreaterThanLower(),
          errorOccurred: true,
          errorType: "upperBound"
        }
      }
      const indexOfNonNumericPercentile = this.percentiles.map((perc) => isNumeric(perc.percentile)).indexOf(false);
      if (indexOfNonNumericPercentile !== -1) {
        return {
          errorMessage: errorMessage.percentileNotANumber(indexOfNonNumericPercentile),
          errorOccurred: true,
          errorType: "percentile",
          errorIdx: indexOfNonNumericPercentile
        }
      }
      const indexOfNonPositivePercentile = this.percentiles.map((perc) => perc.percentile <= 0).indexOf(true);
      if (indexOfNonPositivePercentile !== -1) {
        return {
          errorMessage: errorMessage.percentileTooSmall(indexOfNonPositivePercentile),
          errorOccurred: true,
          errorType: "percentile",
          errorIdx: indexOfNonPositivePercentile
        }
      }
      const indexOfPercentileGreaterThanOne = this.percentiles.map((perc) => perc.percentile >= 1).indexOf(true);
      if (indexOfPercentileGreaterThanOne !== -1) {
        return {
          errorMessage: errorMessage.percentileTooLarge(indexOfPercentileGreaterThanOne),
          errorOccurred: true,
          errorType: "percentile",
          errorIdx: indexOfPercentileGreaterThanOne
        }
      }
      const indexOfNonNumericValue = this.percentiles.map((perc) => isNumeric(perc.value)).indexOf(false);
      if (indexOfNonNumericValue !== -1) {
        return {
          errorMessage: errorMessage.percentileValueNotANumber(indexOfNonNumericValue),
          errorOccurred: true,
          errorType: "percentileValue",
          errorIdx: indexOfNonNumericValue
        }
      }
      const sortedPercentiles = [...this.percentiles].map((p, idx) => ({...p, index: idx})).sort((a, b) => {
        return Number(a.percentile) - Number(b.percentile);
      });
      let tmp = Number(sortedPercentiles[0].value);
      for (let i = 1; i < sortedPercentiles.length; i+=1) {
        const tmpNext = Number(sortedPercentiles[i].value)
        if (tmp > tmpNext) {
          return {
            errorMessage: errorMessage.descendingValues(),
            errorOccurred: true,
            errorType: "percentileValue",
            errorIdx: sortedPercentiles[i].index
          }
        }
        tmp = tmpNext;
      }
      if (isNumeric(this.lowerBound) && Number(this.lowerBound) > Number(sortedPercentiles[0].value)) {
        return {
          errorMessage: errorMessage.lowerBoundGreaterThanSmallestPercentile(),
          errorOccurred: true,
          errorType: "lowerBound"
        }
      }
      if (isNumeric(this.upperBound) && Number(this.upperBound) < Number(sortedPercentiles[sortedPercentiles.length - 1].value)) {
        return {
          errorMessage: errorMessage.upperBoundLessThanHighestPercentile(),
          errorOccurred: true,
          errorType: "upperBound"
        }
      }
      return {
        errorOccurred: false
      }
    },
    prepareMockCharts() {
      const mockPercentiles = initPercentiles;
      const mockResponse = initResponse;
      let percentiles = new Array(2);
      percentiles[0] = new Array();
      percentiles[1] = new Array();
      mockPercentiles.forEach(percentile => {
        percentiles[0].push(percentile.percentile);
        percentiles[1].push(percentile.value);
      });
      this.percentiles = mockPercentiles;
      this.lowerBound = initBounds.loBound;
      this.upperBound = initBounds.hiBound;
      this.metalogResult = this.parseOutput(percentiles, mockResponse.pdf, mockResponse.cdf, mockResponse.coeffs);
      this.metalogResult.lowerBound = "0";
      this.metalogResult.upperBound = "Inf()";
      this.updatePdfCharts();
    },
    prepareData() {
      let result = {
        "pointCount": 1000,
        "percentiles": parsePercentiles(this.percentiles)
      };
      if (isNumeric(this.lowerBound)) {
        result.loBound = Number(this.lowerBound)
      }
      if (isNumeric(this.upperBound)) {
        result.hiBound = Number(this.upperBound)
      }
      return result;
    },
    fetchData() {
      this.trimPercentiles();
      this.formatPercentiles();
      this.error = {
        errorOccurred: false
      };
      const err = this.validateData();
      if (err.errorOccurred) {
        this.error = err;
        popoverErr[err.errorType](err.errorMessage, err.errorIdx);
        return;
      }
      const postData = this.prepareData();
      this.waitingForResponse = true;
      this.selectedChartIdx = -1;
      axios.post("https://metalog.bayesfusion.com/metalog", postData)
          .then((response) => {
            let percentiles = new Array(2);
            percentiles[0] = new Array();
            percentiles[1] = new Array();
            postData.percentiles.forEach(percentile => {
              percentiles[0].push(percentile.percentile);
              percentiles[1].push(percentile.value);
            });
            this.metalogResult = this.parseOutput(percentiles, response.data.pdf, response.data.cdf, response.data.coeffs);
            this.metalogResult.lowerBound = 'loBound' in postData ? postData.loBound : "-Inf()";
            this.metalogResult.upperBound = 'hiBound' in postData ? postData.hiBound : "Inf()";
            this.updatePdfCharts();
          }).catch((err) => {
            this.error = {
              errorOccurred: true,
              errorType: "serverResponseInvalid",
              errorMessage: errorMessage.invalidServerResponse(err.response.data)
            }
      }).finally(() => {
        this.waitingForResponse = false;
      });
    },
    parseOutput(percentiles, _pdf, _cdf, coeffs, loBound="-inf", hiBound="inf") {
      const pdfKeys = Object.keys(_pdf);
      const cdfKeys = Object.keys(_cdf);
      let pdf = {};
      let cdf = {};
      pdfKeys.forEach((key) => {
        pdf[key] = splitInterleavedArrays(_pdf[key]);
      });
      cdfKeys.forEach((key) => {
        cdf[key] = splitInterleavedArrays(_cdf[key]);
      });
      return {
        percentiles,
        pdf,
        cdf,
        coeffs
      }
    },
    updatePdfCharts() {
      for (let i = 0; i < MAX_K; i += 1) {
        if (`${i}` in this.metalogResult.pdf) {
          const avg = mean(this.metalogResult.pdf[`${i}`][0]);
          const stdD = stdDev(this.metalogResult.pdf[`${i}`][0]);
          const chartData = this.chartTemplate(this.metalogResult.pdf[`${i}`][0], [{data: this.metalogResult.pdf[`${i}`][1]}], this.scales, `k=${i}, μ=${avg.toFixed(4)}, σ=${stdD.toFixed(4)}`);
          if (this.charts[i].pdf === null) {
            this.charts[i].pdf = shallowRef(new Chart(document.getElementById(`pdf-chart-${i}`), chartData));
          } else {
            this.charts[i].pdf.data = chartData.data;
            if (chartData.options !== null) {
              this.charts[i].pdf.options = chartData.options;
            }
            this.charts[i].pdf.update();
            // update previous values on chart that exists
          }
          if (this.selectedChartIdx === -1) {
            this.selectChart(i)
          }
        }
      }

    },
    selectChart(idx) {
      this.selectedChartIdx = idx;
      const cdfScales = {
        x: {
          min: this.selectedScales.x.min,
          max: this.selectedScales.x.max
        },
        y: {
          min: 0,
          max: 1
        }
      };
      const pdfChartData = this.chartTemplate(this.metalogResult.pdf[`${idx}`][0], [{data: this.metalogResult.pdf[`${idx}`][1]}], this.selectedScales,  `PDF, k=${idx}`);
      if (this.selectedChart.pdf === null) {
        this.selectedChart.pdf = shallowRef(new Chart(document.getElementById("selected-pdf-chart"), pdfChartData));
      } else {
        this.selectedChart.pdf.data = pdfChartData.data;
        if (pdfChartData.options !== null) {
          this.selectedChart.pdf.options = pdfChartData.options;
        }
        this.selectedChart.pdf.update();
      }

      const scatterData = this.metalogResult.percentiles[0].map((v, idx) => ({
        x: this.metalogResult.percentiles[1][idx],
        y: v
      }));
      const scatterTooltips = scatterData.map((val, idx) => (`Quantile ${idx + 1} at (${val.x}, ${val.y})`));
      const cdfLineChart = {
        data: this.metalogResult.cdf[`${idx}`][1],
        borderColor: this.datasetColor(0)
      };
      const cdfScatter = {
        data: scatterData,
        type: "scatter",
        showLine: false,
        fill: true,
        borderColor: "rgb(0, 0 0)",
        pointRadius: 6,
        backgroundColor: this.datasetColor(1),
        tooltips: scatterTooltips
      };
      const cdfChartData = this.chartTemplate(this.metalogResult.cdf[`${idx}`][0], [cdfScatter, cdfLineChart], cdfScales, `CDF, k=${idx}`);
      if (this.selectedChart.cdf === null) {
        this.selectedChart.cdf = shallowRef(new Chart(document.getElementById("selected-cdf-chart"), cdfChartData));
      } else {
        this.selectedChart.cdf.data = cdfChartData.data;
        if (cdfChartData.options !== null) {
          this.selectedChart.cdf.options = cdfChartData.options;
        }
        this.selectedChart.cdf.update();
      }
    },
    chartTemplate(labels, datasets, scales, title="") {
      let ds = datasets.map((ds, idx) => ({
        type: ds.type ?? "line",
        data: ds.data,
        tension: ds.tension ?? 0.1,
        fill: ds.fill ?? false,
        borderColor: ds.borderColor ?? this.datasetColor(idx),
        showLine: ds.showLine ?? true,
        pointRadius: ds.pointRadius ?? 0,
        pointHitRadius: 0,
        backgroundColor: ds.backgroundColor ?? "rgba(0, 0, 0, 0.1)",
      }));
      return {
        type: 'line',
        data: {
          labels: labels,
          datasets: ds
        },
        options: {
          animation: {
            duration: 0
          },
          hover: {
            animationDuration: 0
          },
          responsiveAnimationDuration: 0,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: title
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: function(tooltipItems, data) {
                  return "";
                },
                label: function(tooltipItem) {
                  if (typeof datasets[tooltipItem.datasetIndex].tooltips !== "undefined") {
                    return datasets[tooltipItem.datasetIndex].tooltips[tooltipItem.dataIndex];
                  }
                  return "";
                }
              }
            }
          },
          showLine: true,
          datasets: {
            line: {
              pointRadius: 0
            }
          },
          scales: {
            x: {
              type: 'linear',
              scaleLabel: {
                display: true
              },
              min: scales.x.min,
              max: scales.x.max,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10
              }
            },
            y: {
              mix: scales.y.min,
              max: scales.y.max
            }
          }
        }
      };
    },
    pdfCount() {
      return Object.keys(this.metalogResult.pdf).length;
    },
    showHelpModal() {
      this.cookies.set("visitedBefore", "true", 60 * 60 * 24 * 30);
      let helpModal = new bootstrap.Modal(document.getElementById('helpModal'));
      helpModal.show();

    },
    checkVisitedCookie() {
      return this.cookies.isKey("visitedBefore");
    }
  },
  mounted() {
    const plugin = {
      id: 'custom_canvas_background_color',
      beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color;
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
      defaults: {
        color: 'white'
      }
    }
    Chart.register(plugin);
    document.getElementById("favicon").setAttribute("href", this.favicon);
    const tmpScales = {
      x: {min: 0, max: 0},
      y: {min: 0, max: 0}
    }
    const tmpChart = this.chartTemplate([], [], tmpScales, "");
    for (let i = 0; i < MAX_K; i += 1) {
      this.charts.push({
        pdf: null,
        cdf: null
      })
    }
    this.selectedChart = {
      pdf: null,
      cdf: null
    }
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// eslint-disable-next-line no-unused-vars
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });
    if (!this.checkVisitedCookie()) {
      this.showHelpModal();
    }
    setTimeout(() => {
      this.prepareMockCharts();
    }, 10);

    let metalogModal = document.getElementById('metalogAModal');
    metalogModal.addEventListener('hidden.bs.modal', function () {
      document.getElementById("getMetalogAButton").blur();
    });
  },
  beforeUpdate() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {
        trigger: 'focus'
      })
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.chart-to-select:hover {
  border: 1px dashed rgba(13, 110, 253,0.6);
}
.chart-to-select {
  border: 1px dotted rgba(255, 255, 255,0.0);
}
.selected-chart {
  border: 1px solid rgba(13, 110, 253,0.6) !important;
}
.h-33 {
  height: 33.333%;
}
.w-33 {
  width: 33.333%;
}
.logo {
  height: 8rem;
  object-fit: scale-down;
}
.instruction {
  margin: auto auto;
}
canvas {
  width: inherit;
}

.form-check-input[type=radio] {
  border-radius: 0 !important;
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
}

.num-input {
  text-align: right;
}
#blocking-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1111;
  cursor: wait;
}

#blocking-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.blur {
  filter: blur(1.5rem);
}

.percentile-list {
  max-height: 55vh;
  overflow-y: auto;
}

.popover-body {
  background-color: #dc3545 !important;
  color: white !important;;
}
.popover {
  --bs-popover-bg: #dc3545 !important;
}

.footer {
  --bs-navbar-padding-y: 0 !important;
  height: 3rem;
}

.dropdown-menu:hover, .dropdown-menu:active {
  display: block !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: inset 0 0 .25rem rgba(13,110,253,.25) !important;
}
</style>
